import MUIInput, { InputProps } from '@mui/joy/Input'
import { FormControl, FormHelperText, FormLabel, Typography } from '@mui/joy'
import { ForwardedRef, forwardRef, useState } from 'react'
import { useFormFieldButton } from './useFormFieldButton'
import styles from './Input.module.sass'

interface SimpleInputProps {
  value?: string | number
  label?: string
  helperText?: string | React.ReactNode
  errors?: string[]
  initiallyTouched?: boolean
  isCompact?: boolean
}

export const Input = forwardRef(
  (
    {
      value,
      onChange,
      label,
      helperText,
      errors,
      error,
      onKeyUp,
      initiallyTouched = false,
      ...rest
    }: SimpleInputProps & InputProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [touched, setTouched] = useState(initiallyTouched)
    return (
      <FormControl size={rest.size}>
        {label && <FormLabel>{label}</FormLabel>}
        <MUIInput
          ref={ref}
          error={error && touched}
          onBlur={() => setTouched(true)}
          onKeyUp={e => {
            setTouched(true)
            onKeyUp?.(e)
          }}
          value={value}
          onChange={onChange}
          {...rest}
        />
        {touched && errors?.[0] && <FormHelperText color="red">{errors[0]}</FormHelperText>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  },
)

export const InputFormField = (props: SimpleInputProps & InputProps & { bottomContent?: React.ReactNode }) => {
  const { isOpen, onBlur, onClick, isTouched } = useFormFieldButton({ isInitiallyTouched: !!props.initiallyTouched })

  const { isCompact, bottomContent, ...rest } = props

  return (
    <>
      {isOpen ? (
        <div className={styles.formOverlay}>
          {props.label && <Typography level="h4">{props.label}</Typography>}
          <Input
            {...rest}
            value={rest.value ?? ''}
            onBlur={e => {
              onBlur()
              props.onBlur?.(e)
            }}
            ref={el => el?.querySelector('input')?.focus()}
            size="lg"
            label={undefined}
          />
          {props.bottomContent}
        </div>
      ) : (
        <Input
          {...rest}
          value={rest.value ?? ''}
          helperText={props.isCompact ? undefined : props.helperText}
          initiallyTouched={isTouched}
          readOnly
          variant="soft"
          onClick={e => {
            onClick()
            props.onClick?.(e)
          }}
        />
      )}
    </>
  )
}
