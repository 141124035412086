import { Checkbox, Chip, CircularProgress, FormControl, FormHelperText, FormLabel, Radio, Slider } from '@mui/joy'
import {
  ProfileFinancialPeriod,
  ProfilePreviewDetails,
  ProfileWorkType,
  profileFinancialPeriodLabels,
} from '../../models/Profile'
import { StepperStep } from '../../ui/Stepper/StepperStep'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { InputFormField } from '../../ui/Input'
import { TextareaFormField } from '../../ui/Textarea'
import { ImageUploader } from '../../ui/ImageCrop/ImageCrop'
import { ProfileDetails } from '../../ui/ProfileDetails/ProfileDetails'
import { useAppState } from '../../ui/AppState'
import { CreateProfileContext } from './CreateProfilePage'
import { MainButtonContext, useDisabledMainButton } from '../../ui/MainButtonContext'
import { isIOS } from '../../tools/ClientUtil'
import citiesList from '../../cities.json'
import { plural } from '../../tools/StringUtil'
import { DangerIcon } from '../../icons/DangerIcon'
import styles from './CreateProfilePage.module.sass'

type StepVariant = 'job' | 'resume'

type GenericComponent = (variant: StepVariant) => { Component: (props: Props) => JSX.Element }

interface Props {
  isCompact?: true
}

const LocationStep = ({ isCompact }: Props) => {
  const { state, onChange, errors: validationErrors } = useSafeContext(CreateProfileContext)

  const allCities = useMemo(() => citiesList.map(({ city }) => city), [])

  const [cities, setCities] = useState<string[]>(['Москва', 'Санкт-Петербург'])
  const [shouldHideHints, setShouldHideHints] = useState(false)

  const errors = [...(validationErrors?.location ? ['Максимальная длина - 64 символов'] : [])]

  useEffect(() => {
    setCities(
      state.location
        ? allCities.filter(c => c.toLowerCase().startsWith(state.location?.toLowerCase() ?? '')).slice(0, 10)
        : ['Москва', 'Санкт-Петербург'],
    )
  }, [state.location, setShouldHideHints, allCities])

  const isDisabled = !!errors.length

  useDisabledMainButton(isDisabled)

  return (
    <StepperStep title={!isCompact && 'Где вы проживаете?'}>
      <div className={styles.locationControlsWrapper}>
        <InputFormField
          isCompact={isCompact}
          label="Город"
          initiallyTouched={!!(errors.length && state.location)}
          helperText={'Данная информация поможет нам в создании наиболее релевантных результатов'}
          placeholder="Например: Москва"
          value={state.location ?? ''}
          onChange={e => onChange('location')(e.target.value)}
          onKeyDown={() => setShouldHideHints(false)}
          errors={errors}
          error={isDisabled}
          bottomContent={
            <>
              {!shouldHideHints &&
                (cities.length !== 1 || cities[0].toLowerCase() !== state.location?.toLowerCase()) && (
                  <div className={styles.citiesHints}>
                    {cities.map((c, i) => (
                      <Chip
                        variant="soft"
                        color="primary"
                        size="md"
                        key={c + i}
                        onMouseDown={() => {
                          onChange('location')(c)
                          setShouldHideHints(true)
                        }}
                      >
                        {c}
                      </Chip>
                    ))}
                  </div>
                )}
            </>
          }
        />
      </div>
    </StepperStep>
  )
}

const PositionStep: GenericComponent = variant => ({
  Component: ({ isCompact }: Props) => {
    const { state, onChange, errors, isEditPage, isOnboardingPage, setRef } = useSafeContext(CreateProfileContext)
    const isDisabled = !!errors?.position
    useDisabledMainButton(isDisabled)

    return (
      <StepperStep title={!isCompact && (variant === 'resume' ? `Ваша должность` : `Кого вы ищете?`)}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <div ref={setRef && setRef('position')}>
            <InputFormField
              readOnly={isEditPage && !isOnboardingPage}
              disabled={isEditPage && !isOnboardingPage}
              isCompact={isCompact}
              initiallyTouched={!!(errors?.position && state.position)}
              label={variant === 'resume' ? 'Ваша должность*' : 'Должность, кого ищете*'}
              helperText={'Данная информация поможет нам в создании наиболее релевантных результатов'}
              placeholder="Например: Эксперт в области фитнеса"
              value={state.position ?? ''}
              onChange={e => onChange('position')(e.target.value)}
              errors={errors?.position ? ['Длина поля должна быть от 2 до 64 символов'] : undefined}
              error={isDisabled}
            />
          </div>
          <FormControl>
            <FormLabel>Формат работы</FormLabel>
            <div className={styles.workTypeStepWrapper} ref={setRef && setRef('workType')}>
              <Checkbox
                label="Full-time"
                checked={state.workType?.includes(ProfileWorkType.FullTime)}
                variant="outlined"
                color="primary"
                onChange={e => {
                  if (e.target.checked) {
                    onChange('workType')([...(state.workType ?? []), ProfileWorkType.FullTime])
                  } else {
                    onChange('workType')(state.workType?.filter(w => w !== ProfileWorkType.FullTime))
                  }
                }}
              />
              <Checkbox
                label="Part-time"
                checked={state.workType?.includes(ProfileWorkType.PartTime)}
                variant="outlined"
                color="primary"
                onChange={e => {
                  if (e.target.checked) {
                    onChange('workType')([...(state.workType ?? []), ProfileWorkType.PartTime])
                  } else {
                    onChange('workType')(state.workType?.filter(w => w !== ProfileWorkType.PartTime))
                  }
                }}
              />
            </div>
            {!isCompact && <FormHelperText>Определите какой тип работы вам подходит</FormHelperText>}
          </FormControl>
        </div>
      </StepperStep>
    )
  },
})

const LookingForDescriptionStep: GenericComponent = variant => ({
  Component: ({ isCompact }: Props) => {
    const { state, onChange, errors, setRef } = useSafeContext(CreateProfileContext)

    const isDisabled = !!errors?.lookingForDescription
    useDisabledMainButton(isDisabled)

    useEffect(() => {
      if (state.experience === undefined) {
        onChange('experience')(0)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange])

    return (
      <StepperStep title={!isCompact && (variant === 'resume' ? 'Что вы ищете?' : 'Кого вы ищете?')}>
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
          ref={setRef && setRef('lookingForDescription')}
        >
          <TextareaFormField
            isCompact={isCompact}
            label={<>Требования {isCompact && !state.lookingForDescription && <DangerIcon />}</>}
            initiallyTouched={!!(errors?.lookingForDescription && state.lookingForDescription)}
            helperText={
              variant === 'resume'
                ? 'Здесь вы можете указать, какие проекты или работа вас заинтересует, ваши требования к компании'
                : 'Здесь вы можете указать необходимые навыки, технологии и любые другие требования к кандидату'
            }
            placeholder={
              variant === 'resume'
                ? 'Например: Хочу работать в опытном коллективе, в котором я смогу опробовать новые инструменты'
                : 'Например: Требуется специалист с опытом в таких программах как Adobe Photoshop, Adobe Illustrator'
            }
            value={state.lookingForDescription}
            onChange={e => onChange('lookingForDescription')(e.target.value)}
            errors={errors?.lookingForDescription ? ['Длина поля должна быть от 10 до 700 символов'] : undefined}
            error={isDisabled}
          />
          {variant === 'job' && (
            <FormControl>
              <FormLabel>Минимальный опыт</FormLabel>
              <Slider
                sx={{
                  padding: '8px',
                }}
                valueLabelDisplay="auto"
                marks
                min={0}
                max={10}
                onChange={(_, experience) => onChange('experience')(experience as number)}
                value={state.experience}
              />
              <FormHelperText>
                {state.experience === 10
                  ? 'Ищем кандидатов с 10+ годами опыта'
                  : !state.experience
                  ? 'Не учитываем опыт'
                  : `Ищем кандидатов с опытом от ${state.experience} ${plural(state.experience, [
                      'года',
                      'лет',
                      'лет',
                    ])}`}
              </FormHelperText>
            </FormControl>
          )}
        </div>
      </StepperStep>
    )
  },
})

const DescriptionStep: GenericComponent = variant => ({
  Component: ({ isCompact }: Props) => {
    const { state, onChange, errors, setRef } = useSafeContext(CreateProfileContext)

    const isDisabled = !!errors?.description
    useDisabledMainButton(isDisabled)
    return (
      <>
        <StepperStep title={!isCompact && (variant === 'resume' ? 'Расскажите о себе' : 'Расскажите о вас')}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }} ref={setRef && setRef('description')}>
            <TextareaFormField
              isCompact={isCompact}
              variant="soft"
              initiallyTouched={!!(errors?.description && state.description)}
              label={
                <>
                  {variant === 'resume' ? 'О себе' : 'О вас и вакансии'}{' '}
                  {isCompact && !state.description && <DangerIcon />}
                </>
              }
              helperText={
                variant === 'resume'
                  ? 'Здесь вы можете указать информацию о себе, своих навыках и прошлом опыте работы'
                  : 'Здесь вы можете рассказать о проекте, вакансии или компании'
              }
              placeholder={
                variant === 'resume'
                  ? 'Например: знание фреймворков React.JS и Angular, отличный командный игрок'
                  : 'Например: На рынке более 15 лет, разрабатываем сайты и другие решения для бизнеса'
              }
              value={state.description}
              onChange={e => onChange('description')(e.target.value)}
              errors={errors?.description ? ['Длина поля должна быть от 10 до 700 символов'] : undefined}
              error={isDisabled}
            />
            {variant === 'resume' && (
              <FormControl>
                <FormLabel>Ваш опыт по специальности</FormLabel>
                <Slider
                  valueLabelDisplay="auto"
                  marks
                  min={0}
                  max={10}
                  onChange={(_, experience) => onChange('experience')(experience as number)}
                  value={state.experience}
                />
                <FormHelperText>
                  {state.experience === 10
                    ? '10 или более лет опыта'
                    : !state.experience
                    ? 'Без опыта'
                    : `Ваш опыт по специальности: ${state.experience} ${plural(state.experience, [
                        'год',
                        'года',
                        'лет',
                      ])}`}
                </FormHelperText>
              </FormControl>
            )}
          </div>
        </StepperStep>
      </>
    )
  },
})

const FinancialStep = ({ isCompact }: Props) => {
  const { state, onChange, errors, setRef } = useSafeContext(CreateProfileContext)

  const isPriceRangeInvalid = (state.financialMin ?? 0) > (state.financialMax ?? state.financialMin ?? 0)

  const isDisabled = !!errors?.financialMin || !!errors?.financialMax || isPriceRangeInvalid || !!errors?.extraInfo
  useDisabledMainButton(isDisabled)

  return (
    <StepperStep title={!isCompact && 'Выберите вариант оплаты труда'}>
      <div className={styles.financialStepWrapper} ref={setRef && setRef('financialPeriod')}>
        <div className={styles.workTypeWrapper}>
          {[ProfileFinancialPeriod.Hour, ProfileFinancialPeriod.Month, ProfileFinancialPeriod.Project].map(p => (
            <Radio
              key={p}
              variant="outlined"
              color="primary"
              label={profileFinancialPeriodLabels[p]}
              onClick={() => onChange('financialPeriod')(state.financialPeriod !== p ? p : undefined)}
              checked={state.financialPeriod === p}
            />
          ))}
        </div>
        {state.financialPeriod && (
          <>
            <InputFormField
              isCompact={isCompact}
              initiallyTouched={
                !!(state.financialMin && (state.financialMin ?? 0) > (state.financialMax ?? state.financialMin ?? 0))
              }
              label="Минимальная сумма (RUB)"
              placeholder="Например: 20000"
              value={state.financialMin ?? ''}
              onChange={e => {
                const amount = parseInt(e.target.value, 10)
                onChange('financialMin')(isNaN(amount) ? undefined : amount)
              }}
              errors={
                errors?.financialMin || !isPriceRangeInvalid
                  ? ['От 0 рублей, но не более максимальной суммы']
                  : undefined
              }
              error={(state.financialMin ?? 0) > (state.financialMax ?? state.financialMin ?? 0)}
              type="number"
            />
            <InputFormField
              isCompact={isCompact}
              id="dummyId"
              initiallyTouched={
                !!(state?.financialMax && (state.financialMin ?? 0) > (state.financialMax ?? state.financialMin ?? 0))
              }
              label="Максимальная сумма (RUB)"
              placeholder="Например: 150000"
              value={state.financialMax ?? ''}
              onChange={e => {
                const amount = parseInt(e.target.value, 10)
                onChange('financialMax')(isNaN(amount) ? undefined : amount)
              }}
              errors={
                errors?.financialMax || isPriceRangeInvalid
                  ? ['От 0 рублей, но не более максимальной суммы']
                  : undefined
              }
              error={(state.financialMin ?? 0) > (state.financialMax ?? state.financialMin ?? 0)}
              type="number"
            />
          </>
        )}
        <TextareaFormField
          isCompact={isCompact}
          initiallyTouched={!!(state.extraInfo && errors?.extraInfo)}
          label="Дополнительно"
          helperText="Здесь вы можете указать любую дополнительную информацию, в том числе об условиях сотрудничества"
          placeholder="Например: работаю по предоплате 50%"
          value={state.extraInfo}
          onChange={e => onChange('extraInfo')(e.target.value)}
          errors={errors?.extraInfo ? ['Длина поля должна быть от 5 до 700 символов'] : undefined}
          error={!!errors?.extraInfo}
        />
      </div>
    </StepperStep>
  )
}

const ProfilePictureStep = ({ isCompact }: Props) => {
  const { state, onChange } = useSafeContext(CreateProfileContext)

  return (
    <StepperStep title={!isCompact && 'Ваша фотография'}>
      <ImageUploader filePath={state.profilePicture ?? ''} setFilePath={onChange('profilePicture')} />
    </StepperStep>
  )
}

const PreviewStep = () => {
  const { state, onChange } = useSafeContext(CreateProfileContext)
  const { user } = useAppState()
  const {
    hideProgress,
    showProgress,
    mainButtonState: { isProgressVisible },
  } = useSafeContext(MainButtonContext)

  const setPreloaderOpen = useCallback(
    (val: boolean) => (val ? showProgress(false) : hideProgress()),
    [showProgress, hideProgress],
  )

  useEffect(() => {
    if (isIOS()) {
      return
    }

    const update = setTimeout(() => {
      setPreloaderOpen(isProgressVisible)
    }, 100)

    return () => {
      clearTimeout(update)
    }
  }, [isProgressVisible, setPreloaderOpen])

  return (
    <StepperStep className={styles.previewStepperStep}>
      {isProgressVisible && (
        <div className={styles.preloaderBackdrop}>
          <div className={styles.preloaderInner}>
            <CircularProgress />
            <span className={styles.preloaderLabel}>Искусственный интеллект обрабатывает вашу анкету</span>
          </div>
        </div>
      )}
      <ProfileDetails
        editable
        profile={
          {
            ...state,
            personName: user.personName,
            birthYear: user.birthYear,
            hasLike: false,
            id: 0,
            variant: state.variant!,
          } as ProfilePreviewDetails
        }
        onChange={onChange}
      />
      {/* {!isEditPage && (
        <Button
          startDecorator={<PremiumStar />}
          variant="soft"
          color={'info' as any}
          size="lg"
          sx={{ fontSize: '14px', width: '100%', marginTop: '16px' }}
          onClick={() => trackPremiumStats('ImproveProfile')}
        >
          Улучшить с помощью ИИ
        </Button>
      )} */}
    </StepperStep>
  )
}

export const createProfileSteps = (variant: 'job' | 'resume'): ((props: Props) => JSX.Element)[] => [
  LocationStep,
  PositionStep(variant).Component,
  ...[variant === 'job' ? LookingForDescriptionStep(variant).Component : DescriptionStep(variant).Component],
  ...[variant === 'job' ? DescriptionStep(variant).Component : LookingForDescriptionStep(variant).Component],
  FinancialStep,
  ProfilePictureStep,
  PreviewStep,
]

export const jobStepsNames = ['Локация', 'Название позиции', 'Кого ищу', 'О вакансии', 'Финансы', 'Фото', 'Превью']
export const resumeStepsNames = ['Локация', 'Название позиции', 'О себе', 'Что ищу', 'Финансы', 'Фото', 'Превью']
