import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UserExplicit, UserId } from '../../models/User'
import { getContact, getUserDetails } from '../../clients/AdminClient'
import { Button, LinearProgress } from '@mui/joy'
import { Card } from '../../ui/Card/Card'
import { ProfileCompactAdminListItem } from './AdminProfilesPage'
import styles from './AdminUserPage.module.sass'

export const AdminUserPage = () => {
  const params = useParams()
  const userId = Number(params.id) as UserId
  const [user, setUser] = useState<UserExplicit>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getUserDetails(userId).then(({ data }) => setUser(data))
  }, [userId])

  return (
    <>
      {user ? (
        <div className={styles.userPageWrapper}>
          <Card>
            <div>
              {user.personName ?? 'No REG'} ( ID: {user.id} )
            </div>
            <div>telegram_id: {user.telegramId}</div>
            <div>birth_year: {user.birthYear ?? 'No REG'}</div>
            <div>has_accepted_tos: {String(user.hasAcceptedTos)}</div>
            <div>onboarding_status: {String(user.onboardingStatus)}</div>
            <div>reg_ip: {String(user.regIp)}</div>
          </Card>
          <Card title="Заинтересованность Премиум">
            {user.premiumStats.map(stats => (
              <div>
                {stats.action}: {stats.count}
              </div>
            ))}
          </Card>
          <Card title="Действия">
            <Button
              sx={{ width: '100%' }}
              variant="soft"
              onClick={() => {
                setLoading(true)
                getContact({ personName: user.personName ?? 'No name', telegramId: user.telegramId }).finally(() => {
                  setLoading(false)
                })
              }}
              loading={loading}
            >
              Получить тг
            </Button>
          </Card>
          <Card>Профили: {user.profilesCount}</Card>
          {user.profiles.map(profile => (
            <ProfileCompactAdminListItem key={profile.id} profile={profile} />
          ))}
        </div>
      ) : (
        <LinearProgress />
      )}
    </>
  )
}
