import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { StepperStep } from '../../ui/Stepper/StepperStep'
import { InputFormField } from '../../ui/Input'
import { birthYearToAge } from '../../tools/StringUtil'
import { RegisterPageContext } from './RegisterPage'
import { NavLink } from 'react-router-dom'
import { useDisabledMainButton } from '../../ui/MainButtonContext'
import styles from './RegisterPage.module.sass'

const TOSStep = () => {
  return (
    <StepperStep title="Условия использования сервиса">
      <p className={styles.tosLabel}>
        Нажимая на кнопку «Продолжить» вы соглашаетесь с{' '}
        <NavLink to="/settings/terms">условиями использования сервиса</NavLink> и{' '}
        <NavLink to="/settings/pd">политикой обработки персональных данных</NavLink>.
      </p>
    </StepperStep>
  )
}

const NameStep = () => {
  const context = useSafeContext(RegisterPageContext)

  const isValid = !context.errors.personName

  useDisabledMainButton(!isValid)

  return (
    <StepperStep title="Как Вас зовут?">
      <InputFormField
        label="Имя*"
        value={context.state.personName ?? ''}
        onChange={e => context.onChange('personName')(e.target.value)}
        required
        helperText={
          <div>
            <div>Имя увидят другие пользователи.</div>
            <b>Имя нельзя будет изменить.</b>
          </div>
        }
        placeholder="Например: Геннадий"
        error={!isValid}
        errors={context.errors.personName ? ['Введите имя от 2 до 12 символов'] : undefined}
      />
    </StepperStep>
  )
}

const AgeStep = () => {
  const { state, onChange, errors } = useSafeContext(RegisterPageContext)

  const isValid = !errors.birthYear

  useDisabledMainButton(!isValid)

  return (
    <StepperStep title="Сколько Вам лет?">
      <InputFormField
        label="Возраст*"
        value={birthYearToAge(state.birthYear) || ''}
        onChange={e => onChange('birthYear')(birthYearToAge(+e.target.value))}
        helperText="Укажите настоящий возраст."
        placeholder="Например: 23"
        type="number"
        errors={errors.birthYear ? ['Возраст должен быть от 18 лет'] : undefined}
      />
    </StepperStep>
  )
}

export const createUserSteps: (() => JSX.Element)[] = [TOSStep, NameStep, AgeStep]
