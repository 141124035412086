import { ProfileContext } from './ProfilePage'
import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { useEffect, useState } from 'react'
import { getProfileDetails } from '../../clients/ProfileClient'
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ProfileDetails } from '../../ui/ProfileDetails/ProfileDetails'
import { ProfileQueueItem } from '../../models/Profile'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { TopBackLink } from '../../ui/TopBackLink/TopBackLink'
import styles from './ProfileViewPage.module.sass'
import { IconButton, LinearProgress } from '@mui/joy'
import { profileToString } from '../../tools/StringUtil'
import similarity from 'similarity'
import { useAppState } from '../../ui/AppState'
import { Edit } from '@mui/icons-material'

export const ProfileViewPage = () => {
  const { profile } = useSafeContext(ProfileContext)
  const { targetId } = useParams()

  const [targetProfile, setTargetProfile] = useState<ProfileQueueItem | null>()

  const [sp] = useSearchParams()
  const isInitial = !!sp.get('start')

  const { user } = useAppState()

  const navigate = useNavigate()

  useEffect(() => {
    if (targetId) {
      getProfileDetails(profile.id, +targetId)
        .then(({ data }) => setTargetProfile(data))
        .catch(() => setTargetProfile(null))
    }
  }, [profile.id, targetId])

  return (
    <PageLayout bottomMenu topMenu title={`${targetProfile?.position ?? 'Загрузка профиля...'}`}>
      <TopBackLink to={isInitial ? `/profile/${profile.id}` : undefined} />
      {targetProfile === undefined && <LinearProgress />}
      {targetProfile?.userId === user.id && !profile.isArchived && (
        <div style={{ display: 'flex', justifyContent: 'end', paddingBottom: '12px' }}>
          <IconButton
            variant="solid"
            color="primary"
            size="sm"
            onClick={() => navigate(`/profile/${profile.id}/edit/`)}
          >
            <Edit fontSize="small" />
          </IconButton>
        </div>
      )}
      {targetProfile && (
        <ProfileDetails
          match={
            profile.id !== targetProfile.id
              ? +(similarity(profileToString(profile), profileToString(targetProfile)) * 100).toFixed(0)
              : undefined
          }
          currentProfile={profile}
          profile={targetProfile}
        />
      )}
      {targetProfile === null && (
        <div className={styles.wrapper}>
          Профиль не найден или был удален. <NavLink to={`/profile/${profile.id}/search`}>Подборка</NavLink>
        </div>
      )}
    </PageLayout>
  )
}
