import React from 'react'
import { Typography } from '@mui/joy'
import styles from './StepperStep.module.sass'

interface StepperStepProps {
  title?: React.ReactNode | string | false
  children: React.ReactNode
  className?: string
}

export const StepperStep = ({ title, children, className }: StepperStepProps) => {
  return (
    <div className={styles.form + `${className ? ' ' + className : ''}`}>
      {title && (
        <Typography level="h4" sx={{ mb: '16px' }}>
          {title}
        </Typography>
      )}
      {children}
    </div>
  )
}
