import { useEffect, useRef, useState } from 'react'
import Cropper, { Area } from 'react-easy-crop'
import styles from './ImageCrop.module.sass'
import { StepperBottomMenu } from '../Stepper/StepperBottomMenu'
import { Button } from '../Button/Button'
import { BackButton } from '../Button/BackButton'
import { uploadImage } from '../../clients/UploadClient'
import config from '../../config'
import { useToast } from '../Toast/Toast'
import { useSafeContext } from '../hooks/useSafeContext'
import { MainButtonContext } from '../MainButtonContext'
import { isIOS } from '../../tools/ClientUtil'

export type CroppedType = { croppedArea: Area; croppedAreaPixels: Area }

export const CroppedImage = ({
  file,
  onFileUploaded,
}: {
  file: File
  onFileUploaded: (filePath: string | null) => void
}) => {
  const [crop, onCropChange] = useState({ x: 0, y: 0 })
  const [zoom, onZoomChange] = useState(0)

  const [croppedAreas, setCroppedAreas] = useState<CroppedType>()
  const [isProcessing, setProcessing] = useState(false)
  const [pngUrl, setPngUrl] = useState<string>()

  const { hide, show } = useSafeContext(MainButtonContext)

  useEffect(() => {
    if (isIOS()) {
      setTimeout(() => {
        hide()
      })
    }
    return () => {
      if (isIOS()) {
        setTimeout(() => {
          show()
        })
      }
    }
  }, [show, hide])

  useEffect(() => {
    if (!file) {
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = event => {
      const image = new Image()
      image.src = event.target?.result as string
      setPngUrl(event.target?.result as string)
    }
  }, [file])

  useEffect(() => {
    setTimeout(() => {
      onZoomChange(1)
    }, 10)
  }, [pngUrl])

  const { showToast } = useToast()

  return (
    <div className={styles.cropperWrapper}>
      <Cropper
        classes={{ containerClassName: styles.imageContainer }}
        image={pngUrl}
        crop={crop}
        zoom={zoom}
        onCropChange={onCropChange}
        onZoomChange={onZoomChange}
        aspect={1 / 1}
        minZoom={1}
        style={{
          cropAreaStyle: {
            borderRadius: '50%',
          },
        }}
        onMediaLoaded={mediaSize => onZoomChange(150 / mediaSize.naturalHeight)}
        onCropComplete={(croppedArea, croppedAreaPixels) =>
          setCroppedAreas({
            croppedArea,
            croppedAreaPixels,
          })
        }
      />
      <StepperBottomMenu anyDevice>
        <BackButton variant="soft" color="neutral" onClick={() => onFileUploaded(null)} disabled={isProcessing} />
        <Button
          fullWidth
          onClick={() => {
            setProcessing(true)
            if (croppedAreas) {
              uploadImage(file, croppedAreas)
                .then(({ data }) => onFileUploaded(data))
                .catch(({ response: { data, status } }) => {
                  if (status === 400) {
                    showToast({
                      title: 'Ошибка при загрузке',
                      description: data ?? 'Что-то пошло не так',
                      variant: 'error',
                    })
                  }
                })
                .finally(() => setProcessing(false))
            }
          }}
          disabled={isProcessing}
          loading={isProcessing}
        >
          Загрузить
        </Button>
      </StepperBottomMenu>
    </div>
  )
}

export const ImageUploader = ({
  filePath,
  setFilePath,
  isCompact,
}: {
  filePath: string
  setFilePath: (path: string) => void
  isCompact?: boolean
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [file, setFile] = useState<File>()
  const [isOpen, setOpen] = useState(false)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0]

    if (file) {
      setFile(file)
      setOpen(true)
    }
  }

  const d = isCompact ? 128 : 234

  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={styles.imagePreview}
          style={{ backgroundImage: `url(${config.API_URL}/${filePath})`, width: d, height: d }}
        ></div>
        <input type="file" ref={inputRef} onChange={onChange} className={styles.input} accept="image/jpeg, image/png" />
        <span
          className={styles.uploadImage}
          onClick={() => inputRef.current?.click()}
          style={isCompact ? { fontSize: 14, fontWeight: 'bold' } : undefined}
        >
          Изменить фото
        </span>
      </div>
      {isOpen && file && (
        <CroppedImage
          onFileUploaded={file => {
            if (inputRef.current) {
              inputRef.current.value = ''
            }
            if (file) {
              setFilePath(file)
            }
            setOpen(false)
          }}
          file={file}
        />
      )}
    </>
  )
}
