import { createContext } from 'react'
import styles from './Stepper.module.sass'
import { LinearProgress } from '@mui/joy'
import { XIcon } from '../../icons/XIcon'
import { isIOS } from '../../tools/ClientUtil'
import { ChevronRightIcon } from '../../icons/ChevronRightIcon'

export const createStepperContext = <T,>(initialValue: T) => createContext<T>(initialValue)

interface StepperProps {
  currentStep: number
  steps: ((props: any) => JSX.Element)[]
  isGrayBackground?: boolean
  onClose?: () => void
  setStep: React.Dispatch<React.SetStateAction<number | undefined>>
  hideBackButton?: boolean
}
export const Stepper = ({ setStep, currentStep, steps, isGrayBackground, onClose }: StepperProps) => {
  if (!steps.length) {
    return null
  }

  const Current = steps[currentStep]

  return (
    <div className={styles.stepWrapper + (isGrayBackground ? ` ${styles.grayBackground}` : ``)}>
      <div className={styles.progressBarWrapper}>
        {isIOS() && currentStep !== 0 && (
          <ChevronRightIcon
            style={{ transform: 'rotateZ(180deg)' }}
            onClick={() => {
              setStep(prev => (prev !== undefined ? (prev > 0 ? prev - 1 : prev) : prev))
            }}
          />
        )}
        <LinearProgress
          color="neutral"
          determinate
          className={styles.stepperProgress}
          value={(100 / (steps.length - 1 || 1)) * currentStep}
        />
        {onClose && <XIcon onClick={onClose} />}
      </div>
      <div style={{ height: '16px' }} />
      <Current />
    </div>
  )
}
