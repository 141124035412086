import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getProfileIncomingLikes } from '../../clients/ProfileClient'
import { ProfileCompact, ProfileQueueItem } from '../../models/Profile'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { BottomNavigation } from '../../ui/BottomNavigation/BottomNavigation'
import { BottomNavigationItem } from '../../ui/BottomNavigation/BottomNavigationItem'
import { Badge, LinearProgress } from '@mui/joy'
import { ProfilesMenu } from '../../ui/ProfilesMenu/ProfilesMenu'
import { getMyProfiles } from '../../clients/UserClient'
import { HouseIcon } from '../../icons/HouseIcon'
import { UsersIcon } from '../../icons/UsersIcon'
import { LikeIcon } from '../../icons/LikeIcon'
import styles from './ProfilePage.module.sass'
import _ from 'lodash'

type ProfileContextState = {
  profile: ProfileCompact
  incomingLikes?: ProfileQueueItem[] | null
  refreshProfile: () => void
  isOnboarding?: boolean
}
export const ProfileContext = createContext<ProfileContextState | null>(null)

interface ProfileContextProviderProps {
  children: React.ReactNode
  profile: ProfileCompact
  incomingLikes?: ProfileQueueItem[] | null
  refreshProfile: () => void
  isOnboarding?: boolean
}
export const ProfileContextProvider = ({
  children,
  profile,
  incomingLikes,
  refreshProfile,
  isOnboarding,
}: ProfileContextProviderProps) => {
  const value = useMemo(
    () => ({
      profile,
      incomingLikes,
      refreshProfile,
      isOnboarding,
    }),
    [profile, incomingLikes, refreshProfile, isOnboarding],
  )

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
}

const tabCn = (isActive: boolean) => styles.navigationItemContent + (isActive ? ` ${styles.active}` : ``)

export const ProfilePage = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  const getTab = (() => {
    if (_.endsWith(pathname, '/search')) {
      return 'profiles'
    }

    if (_.endsWith(pathname, '/likes')) {
      return 'likes'
    }

    return 'main'
  })()

  const [profiles, setProfiles] = useState<ProfileCompact[] | null>()
  const [refreshCounter, setRefreshCounter] = useState(0)

  const refreshProfile = useCallback(() => setRefreshCounter(prev => prev + 1), [setRefreshCounter])

  const profile = profiles?.find(p => p.id === +(id ?? 0))

  const [tab, setTab] = useState<'main' | 'profiles' | 'likes'>(getTab)

  useEffect(() => setTab(getTab), [getTab, setTab])

  const [incomingLikes, setIncomingLikes] = useState<ProfileQueueItem[] | undefined | null>()

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      const profileId = +id
      getMyProfiles()
        .then(({ data }) => setProfiles(data))
        .catch(() => setProfiles(null))

      getProfileIncomingLikes(profileId)
        .then(({ data }) => setIncomingLikes(data))
        .catch(() => setIncomingLikes(null))
    }
  }, [id, refreshCounter])

  useEffect(() => {
    document.title = profile?.position ?? 'Загрузка профиля...'
  }, [profile])

  if (profiles === null || (profiles && !profile)) {
    navigate('/')
  }

  return (
    <>
      {profile ? (
        <ProfileContextProvider refreshProfile={refreshProfile} incomingLikes={incomingLikes} profile={profile}>
          <Outlet />
          <BottomNavigation>
            <BottomNavigationItem
              onClick={() => {
                setTab('main')
                navigate('')
              }}
            >
              <div className={tabCn(tab === 'main')}>
                <HouseIcon className={styles.navigationItemIcon} />
                <span>Главная</span>
              </div>
            </BottomNavigationItem>
            <BottomNavigationItem
              disabled={profile.isArchived}
              onClick={() => {
                setTab('profiles')
                navigate('search')
              }}
            >
              <div className={tabCn(tab === 'profiles')}>
                <UsersIcon className={styles.navigationItemIcon} />
                <span>Подборка</span>
              </div>
            </BottomNavigationItem>
            <BottomNavigationItem
              disabled={profile.isArchived}
              onClick={() => {
                setTab('likes')
                navigate('likes')
              }}
            >
              <div className={tabCn(tab === 'likes')}>
                <Badge badgeContent={profile.isArchived ? 0 : profile.incomingLikesCount} variant="soft" size="sm">
                  <LikeIcon className={styles.navigationItemIcon} />
                </Badge>
                <span>Лайки</span>
              </div>
            </BottomNavigationItem>
            {profiles !== null && <ProfilesMenu profiles={profiles} className={tabCn(false)} />}
          </BottomNavigation>
        </ProfileContextProvider>
      ) : (
        <PageLayout>
          <LinearProgress />
        </PageLayout>
      )}
    </>
  )
}
