import { useState } from 'react'
import styles from './StackOnboarding.module.sass'
import { PersonButtonIcon } from '../../icons/PersonButtonIcon'
import { SwipeDownIcon } from '../../icons/SwipeDownIcon'
import { LikeButtonIcon } from '../../icons/LikeButtonIcon'
import { Button } from '@mui/joy'
import { ArrowLeftIcon } from '../../icons/ArrowLeftIcon'
import { ArrowRightIcon } from '../../icons/ArrowRightIcon'

export const StackOnboarding = ({ onClose, isOnboarding }: { onClose: () => void; isOnboarding?: boolean }) => {
  const [step, setStep] = useState(0)

  return (
    <div className={styles.onboardingWrapper} onClick={() => setStep(prev => (prev === 2 ? 2 : prev + 1))}>
      <div className={styles.frameWrapper}>
        {step === 0 && (
          <div className={styles.stepLabel1}>
            <span>
              Чтобы посмотреть
              <br />
              детали профиля
            </span>
            <div style={{ width: '100%', paddingLeft: 30 }}>
              <ArrowLeftIcon />
            </div>
          </div>
        )}
        {step === 1 && (
          <>
            <div className={styles.stepLabel1}>
              <span>
                Чтобы поставить
                <br />
                лайк
              </span>
              <div style={{ width: '100%', paddingRight: 30, display: 'flex', justifyContent: 'flex-end' }}>
                <ArrowRightIcon />
              </div>
            </div>
            <div className={styles.hintLabel}>
              <span>Или двойное нажатие на карточку</span>
            </div>
          </>
        )}
        {step === 2 && (
          <div className={styles.stepLabel1}>
            <span>Смахните вверх, чтобы перейти к следующей анкете</span>
          </div>
        )}
        <div className={styles.cardControls}>
          <div>{step === 0 && <PersonButtonIcon className={styles.icon} />}</div>
          <div className={styles.skipCol}>
            {step === 2 && (
              <div className={styles.midIcon}>
                <SwipeDownIcon />
              </div>
            )}
          </div>
          <div>{step === 1 && <LikeButtonIcon className={styles.icon} />}</div>
        </div>
      </div>
      {step === 2 && (
        <div className={styles.buttonWrapper}>
          <Button variant="soft" color="neutral" onClick={onClose}>
            Продолжить
          </Button>
        </div>
      )}
    </div>
  )
}
