import { Profile, ProfileCompact, ProfilePreviewDetails, ProfileVariant, ReportReason } from '../../models/Profile'
import { Card } from '../Card/Card'
import { UserDetails } from './UserDetails'
import { Caption } from '../Caption/Caption'
import { WaveIcon } from '../../icons/WaveIcon'
import { useAppState } from '../AppState'
import { UserId } from '../../models/User'
import { useContext, useMemo, useState } from 'react'
import { Alert, Button, Radio, RadioGroup } from '@mui/joy'
import { PageLayout } from '../PageLayout/PageLayout'
import { Textarea } from '../Textarea'
import { useToast } from '../Toast/Toast'
import { reportProfile, sendContact } from '../../clients/ProfileClient'
import { useSafeContext } from '../hooks/useSafeContext'
import { ProfileContext } from '../../pages/ProfilePage/ProfilePage'
import { createProfileSteps } from '../../pages/CreateProfile/createProfileSteps'
import { DangerIcon } from '../../icons/DangerIcon'
import { ImageUploader } from '../ImageCrop/ImageCrop'
import { CreateProfileContext } from '../../pages/CreateProfile/CreateProfilePage'
import { useNavigate } from 'react-router-dom'
import styles from './ProfileDetails.module.sass'

interface ProfileDetailsProps {
  profile: ProfilePreviewDetails
  currentProfile?: ProfileCompact
  onLike?: (profileId: number) => void
  match?: number
  editable?: boolean
  onChange?: <K extends keyof Profile>(name: K) => (value: Partial<Profile>[K]) => void
}

// TODO обновить сообщение, когда сделаем публикацию в канал
const NotFullProfileWarning = ({ profile, editing }: { profile: Partial<Partial<Profile>>; editing?: boolean }) => {
  const navigate = useNavigate()
  return (
    <Alert color="warning">
      <div>
        <span>Чтобы получить доступ ко всем функциям, пожалуйста, заполните поля:</span>
        {!profile.lookingForDescription && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <DangerIcon /> <b>Требования</b>
          </div>
        )}
        {!profile.description && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <DangerIcon /> <b>О себе</b>
          </div>
        )}
        {!editing && (
          <Button
            color="warning"
            variant="solid"
            sx={{ marginTop: '10px' }}
            onClick={() => navigate(`/profile/${profile.id}/edit/`)}
          >
            Редактировать
          </Button>
        )}
      </div>
    </Alert>
  )
}

const isFullProfile = (profile: ProfilePreviewDetails) => profile.description && profile.lookingForDescription

const ProfileVariantResume = ({ profile }: ProfileDetailsProps) => {
  return (
    <>
      {profile.description && (
        <Card title="О себе">
          <span className={styles.detailsCardText}>{profile.description}</span>
        </Card>
      )}
      {profile.lookingForDescription && (
        <Card title="Что ищу">
          <span className={styles.detailsCardText}>{profile.lookingForDescription}</span>
        </Card>
      )}
      {profile.extraInfo && (
        <Card title="Дополнительная информация">
          <span className={styles.detailsCardText}>{profile.extraInfo}</span>
        </Card>
      )}
    </>
  )
}

const EditProfileForm = ({ profile, setEditing }: ProfileDetailsProps & { setEditing: (val: boolean) => void }) => {
  const steps = useMemo(
    () =>
      createProfileSteps({ [ProfileVariant.Job]: 'job', [ProfileVariant.Resume]: 'resume' }[profile.variant] as any),
    [profile.variant],
  )
  const { onChange, isEditPage, isOnboardingPage } = useSafeContext(CreateProfileContext)

  return (
    <div className={styles.editableProfileWrapper}>
      {!isEditPage && !isOnboardingPage && (
        <Button onClick={() => setEditing(false)} variant="outlined" color="success">
          Сохранить
        </Button>
      )}
      {!isFullProfile(profile) && !isOnboardingPage && <NotFullProfileWarning editing profile={profile} />}
      <div>
        <ImageUploader
          isCompact
          filePath={profile.profilePicture ?? ''}
          setFilePath={path => onChange('profilePicture')(path)}
        />
      </div>
      {/* <ProfileTags profile={profile} tags={profile.tags} onChange={onChange} compact /> */}
      {steps.slice(0, steps.length - 2).map((Component, i) => (
        <Component key={i} isCompact />
      ))}
    </div>
  )
}

const ProfileVariantJob = ({ profile }: ProfileDetailsProps) => {
  return (
    <>
      {profile.description && (
        <Card title="О вакансии">
          <span className={styles.detailsCardText}>{profile.description}</span>
        </Card>
      )}
      {profile.lookingForDescription && (
        <Card title="Идеальный кандидат">
          <span className={styles.detailsCardText}>{profile.lookingForDescription}</span>
        </Card>
      )}
      {profile.extraInfo && (
        <Card title="Дополнительная информация">
          <span className={styles.detailsCardText}>{profile.extraInfo}</span>
        </Card>
      )}
    </>
  )
}

interface ReportModalProps {
  onRequestClose: () => void
  onSuccess: () => void
  targetProfileId: number
}
const ReportModal = ({ onRequestClose, onSuccess, targetProfileId }: ReportModalProps) => {
  const [reason, setReason] = useState<ReportReason>()
  const [extra, setExtra] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { profile } = useSafeContext(ProfileContext)

  const { showToast } = useToast()

  const isInvalid = extra.length > 100

  return (
    <PageLayout className={styles.reportWrapper}>
      <Caption className={styles.title}>Укажите причину жалобы</Caption>
      <div className={styles.reportReasons}>
        <RadioGroup>
          <Radio
            checked={reason === ReportReason.InappropriateContent}
            onChange={() => setReason(ReportReason.InappropriateContent)}
            label="Запрещенный контент"
          />
          <Radio
            checked={reason === ReportReason.FakeInfo}
            onChange={() => setReason(ReportReason.FakeInfo)}
            label="Ложная информация в анкете"
          />
          <Radio
            checked={reason === ReportReason.Scam}
            onChange={() => setReason(ReportReason.Scam)}
            label="Пользователь оказался мошенником"
          />
          <Radio
            checked={reason === ReportReason.IndecentBehaviour}
            onChange={() => setReason(ReportReason.IndecentBehaviour)}
            label="Непристойные или оскорбительные сообщения"
          />
          <Radio
            checked={reason === ReportReason.Other}
            onChange={() => setReason(ReportReason.Other)}
            label="Другое"
          />
        </RadioGroup>
        <Textarea
          label="Дополнительная информация"
          placeholder="Подробности, которые помогут нам помочь разобраться в ситуации"
          variant="soft"
          minRows={3}
          maxRows={3}
          onChange={e => setExtra(e.target.value)}
          value={extra}
          helperText="Дайте нам больше деталей, чтобы мы могли разобраться в ситуации"
          error={isInvalid}
          errors={isInvalid ? ['Не более 100 символов'] : undefined}
        />
        <div className={styles.reportButtons}>
          <Button
            loading={isLoading}
            fullWidth
            variant="soft"
            onClick={() => {
              if (!reason) {
                return
              }

              setLoading(true)

              showToast({
                title: 'Жалоба отправлена',
                description: 'Спасибо! Мы обязательно рассмотрим вашу жалобу.',
                variant: 'default',
              })
              reportProfile({
                profileId: profile.id,
                targetProfileId,
                comment: extra,
                reason,
              })
                .then(() => {
                  onSuccess()
                  onRequestClose()
                })
                .finally(() => {
                  setLoading(false)
                })
            }}
            disabled={!reason}
          >
            Пожаловаться
          </Button>
          <Button fullWidth variant="soft" color="neutral" onClick={onRequestClose}>
            Отмена
          </Button>
        </div>
      </div>
    </PageLayout>
  )
}

// const ProfileTagsEdit = ({
//   tags,
//   setTags,
//   generate,
//   loading,
//   onChange,
//   setEditing,
//   profileTags,
// }: {
//   tags?: string[]
//   setTags: React.Dispatch<React.SetStateAction<string[] | undefined>>
//   generate: () => void
//   loading: boolean
//   setEditing: React.Dispatch<React.SetStateAction<boolean>>
//   onChange: <K extends keyof Profile>(name: K) => (value: Partial<Profile>[K]) => void
//   profileTags: string[]
// }) => {
//   const [open, setOpen] = useState<undefined | boolean>(true)
//   const [tag, setTag] = useState('')
//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       setOpen(undefined)
//     }, 1500)

//     return () => {
//       clearTimeout(timeout)
//     }
//   }, [setOpen])
//   return (
//     <div className={styles.tagsOverlay}>
//       <div className={styles.tagsHeader}>
//         <Typography level="h4">Изменить теги</Typography>
//         <div style={{ display: 'flex', gap: '4px' }}>
//           <Tooltip title="Сгенерировать с ИИ" arrow open={open}>
//             <IconButton disabled={loading} size="sm" variant="soft" color="success" onClick={generate}>
//               <RestartAlt fontSize="small" />
//             </IconButton>
//           </Tooltip>
//           <IconButton
//             size="sm"
//             variant="soft"
//             color="primary"
//             disabled={(tags ? tags.length : 0) > 10 || tags?.length === 0 || loading}
//             onClick={() => {
//               onChange('tags')(tags)
//               setEditing(prev => !prev)
//             }}
//           >
//             <Check fontSize="small" />
//           </IconButton>
//           <IconButton
//             disabled={loading}
//             size="sm"
//             color="danger"
//             variant="soft"
//             onClick={() => {
//               setTags(profileTags)
//               setEditing(prev => !prev)
//             }}
//           >
//             <Close fontSize="small" />
//           </IconButton>
//         </div>
//       </div>
//       <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
//         <div style={{ display: 'flex', paddingTop: '12px', gap: '8px' }}>
//           <Input
//             value={tag}
//             placeholder="Введите свой тег"
//             onChange={e => setTag(e.target.value)}
//             size="lg"
//             sx={{ width: '100%' }}
//             error={tag.length > 24}
//           />
//           <IconButton
//             size="lg"
//             color="primary"
//             variant="soft"
//             disabled={!tag.trim() || (tags?.length ?? 0) >= 10 || loading || tag.trim().length > 64}
//             onClick={() => {
//               !tags?.map(t => t.toLocaleLowerCase()).includes(tag.toLowerCase()) &&
//                 setTags(prev => prev && [...prev, tag])
//               setTag('')
//             }}
//           >
//             <Add fontSize="small" />
//           </IconButton>
//         </div>
//         {tag.length > 24 ? (
//           <FormHelperText>Максимальная длина тега - 24 символа</FormHelperText>
//         ) : (
//           <>
//             {(tags?.length ?? 0) >= 10 && <FormHelperText>Достигнут лимит в 10 тегов</FormHelperText>}
//             {!tags && <FormHelperText>Добавьте минимум 1 тег</FormHelperText>}
//           </>
//         )}
//       </div>
//       <div className={styles.tagsWrapper}>
//         {loading ? (
//           <LinearProgress />
//         ) : (
//           <>
//             {tags && !tags.length && (
//               <Alert size="sm" color="danger" variant="soft">
//                 Не получилось сгенерировать теги. Добавьте минимум 1 тег вручную.
//               </Alert>
//             )}
//           </>
//         )}
//         {tags?.map(t => (
//           <Chip
//             key={t}
//             variant="soft"
//             color={'info' as any}
//             size="md"
//             endDecorator={
//               <ChipDelete
//                 variant="soft"
//                 disabled={loading}
//                 onDelete={() => {
//                   setTags(prev => prev && prev.filter(tag => t !== tag))
//                 }}
//               />
//             }
//           >
//             {t.toUpperCase()}
//           </Chip>
//         ))}
//       </div>
//     </div>
//   )
// }

// const ProfileTags = ({
//   profile,
//   editable,
//   onChange,
//   compact,
// }: {
//   profile: Partial<Profile>
//   tags: string[]
//   editable?: boolean
//   compact?: boolean
//   onChange?: <K extends keyof Profile>(name: K) => (value: Partial<Profile>[K]) => void
// }) => {
//   const [tags, setTags] = useState<string[] | undefined>(profile.tags)

//   const { onBlur, onClick, isOpen } = useFormFieldButton({ isInitiallyTouched: false })

//   const [loading, setLoading] = useState(false)
//   const generate = useCallback(() => {
//     setTags([])

//     if (
//       !profile.lookingForDescription ||
//       profile.lookingForDescription.length < 10 ||
//       !profile.description ||
//       profile.description.length < 10
//     ) {
//       return
//     }

//     setLoading(true)
//     generateTags(profile)
//       .then(r => {
//         if (!r.data.length) {
//           return
//         }
//         const data = r.data.map(t => t.slice(0, 24))
//         setTags(data)
//         onChange?.('tags')(data)
//       })
//       .finally(() => setLoading(false))
//   }, [setLoading, profile, onChange])

//   useEffect(() => {
//     if (tags === undefined) {
//       generate()
//     }
//   }, [tags, generate])

//   return (
//     <>
//       {!isOpen ? (
//         <>
//           {compact ? (
//             <div>
//               <FormLabel sx={{ marginBottom: '6px' }}>Теги</FormLabel>
//               <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
//                 {tags?.map(t => (
//                   <Chip key={t} color="primary" variant="outlined" size="sm">
//                     {t.toUpperCase()}
//                   </Chip>
//                 ))}
//                 <Chip color="primary" variant="solid" startDecorator={<Edit />} size="sm" onClick={() => onClick()}>
//                   ИЗМЕНИТЬ
//                 </Chip>
//               </div>
//             </div>
//           ) : (
//             <>
//               {tags && (
//                 <Card
//                   title={`Теги анкеты ${isOpen ? tags.length + '/' + 10 : ''}`}
//                   rightTitleContent={
//                     editable
//                       ? () => (
//                           <IconButton size="sm" variant="soft" onClick={onClick}>
//                             <Edit fontSize="small" />
//                           </IconButton>
//                         )
//                       : undefined
//                   }
//                 >
//                   <div className={styles.tags}>
//                     {tags?.map(t => (
//                       <Chip
//                         key={t}
//                         variant="soft"
//                         color={'info' as any}
//                         size="sm"
//                         endDecorator={
//                           isOpen ? (
//                             <ChipDelete
//                               variant="soft"
//                               disabled={loading}
//                               onDelete={() => {
//                                 setTags(prev => prev && prev.filter(tag => t !== tag))
//                               }}
//                             />
//                           ) : undefined
//                         }
//                       >
//                         {t.toUpperCase()}
//                       </Chip>
//                     ))}
//                     {loading ? (
//                       <CircularProgress size="sm" />
//                     ) : (
//                       <>
//                         {tags && !tags.length && (
//                           <Alert size="sm" color="danger" variant="soft">
//                             Не получилось сгенерировать теги. Добавьте минимум 1 тег вручную.
//                           </Alert>
//                         )}
//                       </>
//                     )}
//                   </div>
//                 </Card>
//               )}
//             </>
//           )}
//         </>
//       ) : (
//         <>
//           {onChange && (
//             <ProfileTagsEdit
//               tags={tags}
//               setTags={setTags}
//               generate={generate}
//               loading={loading}
//               onChange={onChange}
//               profileTags={profile.tags ?? []}
//               setEditing={() => onBlur()}
//             />
//           )}
//         </>
//       )}
//     </>
//   )
// }

export const ProfileDetails = ({ profile, onLike, match, currentProfile, editable, onChange }: ProfileDetailsProps) => {
  const { user } = useAppState()

  const [isReportModalOpen, setReportModalOpen] = useState(false)
  const [isProcessing, setProcessing] = useState(false)

  const { showToast } = useToast()

  const context = useContext(CreateProfileContext)
  const [editing, setEditing] = useState(context && context.isEditPage)

  if (editing) {
    return <EditProfileForm profile={profile} setEditing={setEditing} />
  }

  return (
    <>
      {!isReportModalOpen ? (
        <div className={styles.profileWrapper}>
          {((profile.userId && profile.userId === user.id) || editable) &&
            !isFullProfile(profile) &&
            !currentProfile?.isArchived && <NotFullProfileWarning editing={editable} profile={profile} />}
          <Card>
            <UserDetails
              birthYear={profile.birthYear}
              personName={profile.personName}
              profilePicture={profile.profilePicture}
              location={profile.location}
              title={profile.position}
              workType={profile.workType}
              financialMin={profile.financialMin}
              financialMax={profile.financialMax}
              financialPeriod={profile.financialPeriod}
              hasLike={profile.hasLike}
              match={match}
              experience={profile.experience}
              variant={profile.variant}
              onEdit={editable && !context?.isOnboardingPage ? setEditing : undefined}
            />
          </Card>
          {/* {editable ? (
            <>
              {((profile.tags && profile.tags.length > 0) || profile.description || profile.lookingForDescription) && (
                <ProfileTags profile={profile} editable={editable} tags={profile.tags ?? []} onChange={onChange} />
              )}
            </>
          ) : (
            <>
              {profile?.tags && profile.tags.length > 0 && (
                <Card title="Теги анкеты">
                  <div className={styles.tags}>
                    {profile.tags?.map(t => (
                      <Chip key={t} variant="soft" color={'info' as any} size="sm">
                        {t.toUpperCase()}
                      </Chip>
                    ))}
                  </div>
                </Card>
              )}
            </>
          )} */}
          {profile.variant === ProfileVariant.Resume ? (
            <ProfileVariantResume profile={profile} />
          ) : (
            <ProfileVariantJob profile={profile} editable={editable} />
          )}
          {(onLike ||
            (currentProfile && (profile.userId as unknown as UserId) !== user.id && profile.telegramId) ||
            (!!profile.id && (profile.userId as unknown as UserId) !== user.id)) && (
            <div style={{ paddingTop: 16, display: 'flex', flexDirection: 'column', gap: 8 }}>
              {onLike && (
                <Button fullWidth variant="soft" onClick={() => onLike(profile.id)}>
                  <WaveIcon style={{ width: 28, height: 28 }} /> Интересно
                </Button>
              )}
              {currentProfile && (profile.userId as unknown as UserId) !== user.id && profile.telegramId && (
                <Button
                  fullWidth
                  variant="soft"
                  onClick={() => {
                    setProcessing(true)
                    sendContact(currentProfile.id, profile.id).then(() => {
                      setProcessing(false)
                      showToast({
                        title: 'Контакт отправлен',
                        description: 'Ссылка была отправлена вам в Telegram',
                      })
                    })
                  }}
                  disabled={isProcessing}
                  loading={isProcessing}
                >
                  <WaveIcon style={{ width: 28, height: 28 }} /> Получить контакт
                </Button>
              )}
              {!!profile.id && (profile.userId as unknown as UserId) !== user.id && (
                <Button
                  color="neutral"
                  variant="soft"
                  fullWidth
                  onClick={() => {
                    setReportModalOpen(true)
                    document.body.style.overflow = 'hidden'
                  }}
                >
                  Пожаловаться
                </Button>
              )}
            </div>
          )}
        </div>
      ) : (
        <ReportModal
          onRequestClose={() => {
            setReportModalOpen(false)
            document.body.style.overflow = 'auto'
          }}
          onSuccess={() => {
            // todo
          }}
          targetProfileId={profile.id}
        />
      )}
    </>
  )
}
