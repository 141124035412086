import { useEffect, useState } from 'react'
import { RegisterPage } from './pages/RegisterPage/RegisterPage'
import { User } from './models/User'
import { getUserDetails, launch, validateUser } from './clients/UserClient'
import { AppMountingContextProvider, AppState, useAppState } from './ui/AppState'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { CreateProfilePage } from './pages/CreateProfile/CreateProfilePage'
import { ToastContextProvider } from './ui/Toast/Toast'
import { MainPage } from './pages/MainPage/MainPage'
import { ProfilePage } from './pages/ProfilePage/ProfilePage'
import { TitleProfilePage } from './pages/ProfilePage/TitleProfilePage'
import { ProfileMatchesPage } from './pages/ProfilePage/ProfileMatchesPage'
import { ProfileViewPage } from './pages/ProfilePage/ProfileViewPage'
import { ProfileSearchResultsPage } from './pages/ProfilePage/ProfileSearchResultsPage'
import { ProfileLikesPage } from './pages/ProfilePage/ProfileLikesPage'
import { SettingsPage } from './pages/SettingsPage/SettingsPage'
import { SettingsMenuPage } from './pages/SettingsPage/SettingsMenuPage'
import { TermsOfUsePage } from './pages/SettingsPage/TermsOfUsePage'
import { ContactSupportPage } from './pages/SettingsPage/ContactSupportPage'
import { PrivacyPolicyPage } from './pages/SettingsPage/PrivacyPolicyPage'
import { MainButton } from './models/Telegram'
import { MainButtonContextProvider } from './ui/MainButtonContext'
import { ContractedPlaceholder } from './ui/ContractedPlaceholder/ContractedPlaceholder'
import { getKey } from './tools/LocalStorageUtil'
import { AdminMenuPage, AdminPage } from './pages/AdminPage/AdminPage'
import { AdminProfilesPage } from './pages/AdminPage/AdminProfilesPage'
import { AdminProfileViewPage } from './pages/AdminPage/AdminProfileViewPage'
import { AdminUsersPage } from './pages/AdminPage/AdminUsersPage'
import { AdminUserPage } from './pages/AdminPage/AdminUserPage'
import { AdminCreateProfileLogsPage } from './pages/AdminPage/AdminCreateProfileLogsPage'
import { CssVarsProvider } from '@mui/joy'
import { joyTheme } from './theme'
import { EditProfilePage } from './pages/CreateProfile/EditProfilePage'
import { OnboardingPage } from './pages/OnboardingPage/OnboardingPage'
import './App.module.sass'

const w = window as any

export const tg = w.Telegram.WebApp as any
export const mainButton = tg.MainButton as MainButton

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem(getKey('auth-token'))
  config.headers['auth-token'] = token

  return config
})

const AppRoutes = () => {
  const {
    user: { canManageProfiles, canSeeStats },
  } = useAppState()
  return (
    <Routes>
      <Route path="/tour" element={<OnboardingPage />} />
      <Route path={'/create-profile'} element={<CreateProfilePage />} />
      <Route path="/profile/:id" element={<ProfilePage />}>
        <Route index element={<TitleProfilePage />} />
        <Route path="/profile/:id/matches" element={<ProfileMatchesPage />} />
        <Route path="/profile/:id/search" element={<ProfileSearchResultsPage />} />
        <Route path="/profile/:id/likes" element={<ProfileLikesPage />} />
        <Route path="/profile/:id/view/:targetId" element={<ProfileViewPage />} />
        <Route path="/profile/:id/edit" element={<EditProfilePage />} />
      </Route>
      <Route path={'/settings'} element={<SettingsPage />}>
        <Route index element={<SettingsMenuPage />} />
        <Route path="/settings/terms" element={<TermsOfUsePage />} />
        <Route path="/settings/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/settings/contact" element={<ContactSupportPage />} />
      </Route>
      {canManageProfiles && (
        <Route path={'/admin'} element={<AdminPage />}>
          <Route index element={<AdminMenuPage />} />
          <Route path="/admin/profile-creation" element={<AdminCreateProfileLogsPage />} />
          <Route path="/admin/swipes" element={<TermsOfUsePage />} />
          <Route path="/admin/profiles" element={<AdminProfilesPage />} />
          <Route path="/admin/profile/:id" element={<AdminProfileViewPage />} />
          <Route path="/admin/users" element={<AdminUsersPage />} />
          <Route path="/admin/user/:id" element={<AdminUserPage />} />
          <Route path="/admin/app" element={<ContactSupportPage />} />
        </Route>
      )}
      {canSeeStats && <Route path={'/stats'} element={<SettingsPage />} />}
      <Route path={'/'} element={<MainPage />} />
    </Routes>
  )
}

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate()

  const [userDetails, setUserDetails] = useState<User | undefined | null>()

  useEffect(() => {
    const token = localStorage.getItem(getKey('auth-token'))
    if (!token) {
      validateUser(tg.initData)
        .then(({ data: response }) => {
          const { user, token } = response
          localStorage.setItem(getKey('auth-token'), token)
          setUserDetails(user)
        })
        .catch(() => {
          setUserDetails(null)
        })
    } else {
      getUserDetails()
        .then(({ data: response }) => {
          setUserDetails(response)
        })
        .catch(() => {
          localStorage.removeItem(getKey('auth-token'))
          setUserDetails(null)
        })
    }
  }, [setUserDetails])

  if (userDetails === undefined) {
    return null
  }

  const isOnboarding = userDetails?.isOnboardingPassed === false

  if (window.location.pathname === '/main') {
    // TODO fix random blank screen with Navigate below
    // return <Navigate to={'/create-profile'} />

    if (
      localStorage.getItem(getKey('profile-creation-state')) ||
      (userDetails?.personName && userDetails?.everCreatedProfilesCount === 0)
    ) {
      navigate('/create-profile')
    } else {
      navigate('/')
    }

    return null
  }

  return userDetails ? (
    <AppState user={userDetails}>
      {userDetails?.personName ? (
        children
      ) : (
        <Routes>
          <Route path="*" element={isOnboarding ? <OnboardingPage /> : <RegisterPage />} />
          <Route path={'/settings'} element={<SettingsPage />}>
            <Route path="/settings/terms" element={<TermsOfUsePage />} />
            <Route path="/settings/pd" element={<PrivacyPolicyPage />} />
          </Route>
        </Routes>
      )}
    </AppState>
  ) : (
    <>
      Пожалуйста, войдите в приложение через телеграм-бот. Если вы уже открыли приложение через бот, попробуйте
      перезапутить его.
    </>
  )
}

function App() {
  const [isAppShown, setAppShown] = useState(true)

  useEffect(() => {
    tg.ready()
    tg.enableClosingConfirmation()
    tg.expand()

    launch()

    const refresh = () => setAppShown(tg.isExpanded)

    let timeout = setTimeout(refresh, 100)

    const handleViewportChanged = () => {
      clearTimeout(timeout)
      timeout = setTimeout(refresh, 100)
    }

    tg.onEvent('viewportChanged', handleViewportChanged)

    return () => {
      tg.offEvent('viewportChanged', handleViewportChanged)
    }
  }, [])

  return (
    <CssVarsProvider theme={joyTheme}>
      <AppMountingContextProvider>
        <BrowserRouter>
          <ToastContextProvider>
            <MainButtonContextProvider>
              <AuthProvider>
                <AppRoutes />
                {!isAppShown && <ContractedPlaceholder />}
              </AuthProvider>
            </MainButtonContextProvider>
          </ToastContextProvider>
        </BrowserRouter>
      </AppMountingContextProvider>
    </CssVarsProvider>
  )
}

export default App
