import { AvatarGroup, Button, Chip, Avatar as MUIAvatar, Modal, ModalDialog, Typography } from '@mui/joy'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { StatsSlider } from '../../ui/StatsSlider/StatsSlider'
import styles from './TitleProfilePage.module.sass'
import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { ProfileContext } from './ProfilePage'
import { plural } from '../../tools/StringUtil'
import { useNavigate } from 'react-router-dom'
import config from '../../config'
import { useCallback, useState } from 'react'
import { markAsArchived } from '../../clients/ProfileClient'
import { TrashBinIcon } from '../../icons/TrashBinIcon'
import { Avatar } from '../../ui/Avatar/Avatar'
import { ProfileVariant } from '../../models/Profile'

export const TitleProfilePage = () => {
  const { profile, incomingLikes, refreshProfile } = useSafeContext(ProfileContext)

  const navigate = useNavigate()

  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const surplus = incomingLikes && incomingLikes.length > 3 ? incomingLikes.length - 3 : 0

  const [isProcessing, setProcessing] = useState(false)

  const archiveProfile = useCallback(async () => {
    setConfirmDialogOpen(false)
    setProcessing(true)
    markAsArchived(profile.id)
      .then(refreshProfile)
      .catch(e => console.log(e))
      .finally(() => setProcessing(false))
  }, [profile.id, refreshProfile])

  if (!incomingLikes) {
    return null
  }

  return (
    <>
      <div className={styles.profileWrapper}>
        <div>
          <Avatar size={48} profilePicture={profile.profilePicture} />
        </div>
        <div className={styles.profileDetails}>
          <span className={styles.positionName}>{profile.position}</span>
          <span className={styles.profileVariant}>
            {profile.variant === ProfileVariant.Job ? 'Вакансия' : 'Резюме'}
          </span>
        </div>
        <div className={styles.openButton}>
          <Chip
            size="sm"
            color="primary"
            variant="soft"
            onClick={() => navigate(`/profile/${profile.id}/view/${profile.id}`)}
            disabled={profile.isArchived}
          >
            Изменить
          </Chip>
        </div>
      </div>
      {!profile.isArchived && (
        <div style={{ paddingTop: 24, height: '40vh' }}>
          <StatsSlider profileId={profile.id} />
        </div>
      )}
      <PageLayout className={styles.pageLayout + (profile.isArchived ? ' ' + styles.fullHeight : '')}>
        <div className={styles.tiles}>
          {incomingLikes.length > 0 && !profile.isArchived && (
            <div className={styles.tile + ' ' + styles.incomingLikesTile}>
              <span>
                {incomingLikes.length} {plural(incomingLikes.length, ['человек ждут', 'человека ждут', 'человек ждет'])}{' '}
                отклика
              </span>
              <div className={styles.avatarsGroup}>
                <AvatarGroup>
                  {incomingLikes?.slice(0, 3).map((l, i) => (
                    <MUIAvatar src={config.API_URL + '/' + l.profilePicture} key={l.id + i} />
                  ))}
                  {!!surplus && <MUIAvatar>+{surplus}</MUIAvatar>}
                </AvatarGroup>
                <div className={styles.button}>
                  <Chip size="sm" color="primary" variant="soft" onClick={() => navigate('likes')}>
                    Смотреть
                  </Chip>
                </div>
              </div>
            </div>
          )}
          <div className={styles.tile + ' ' + styles.historyTile}>
            <span style={{ display: 'flex', alignItems: 'center' }}>История взаимных лайков</span>
            <Chip size="sm" color="primary" variant="soft" onClick={() => navigate('matches')}>
              Смотреть
            </Chip>
          </div>
        </div>
        {!profile.isArchived ? (
          <Button
            variant="outlined"
            color="neutral"
            fullWidth
            style={{ gap: 8, color: '#5A5A72' }}
            onClick={() => setConfirmDialogOpen(true)}
            loading={isProcessing}
            disabled={isProcessing}
          >
            <TrashBinIcon style={{ width: 24, height: 24 }} />
            Удалить профиль
          </Button>
        ) : (
          <span className={styles.archivedInfo}>Вы удалили этот профиль</span>
        )}
        {isConfirmDialogOpen && (
          <Modal open={isConfirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
            <ModalDialog variant="outlined" role="alertdialog">
              <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                <Typography level="h4">Вы уверены?</Typography>
                <Typography textColor="text.tertiary">
                  Вы действительно хотите удалить профиля? Это действие нельзя отменить.
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button onClick={() => setConfirmDialogOpen(false)} color="neutral" variant="plain">
                    Отмена
                  </Button>
                  <Button color="danger" onClick={archiveProfile}>
                    Удалить
                  </Button>
                </div>
              </div>
            </ModalDialog>
          </Modal>
        )}
      </PageLayout>
    </>
  )
}
